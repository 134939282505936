import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Close from '../../assets/icons/Close';

interface FullscreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const FullscreenModal = ({ children, isOpen, onClose }: FullscreenModalProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0" open={isOpen} onClose={onClose} initialFocus={ref}>
        <div className="flex items-end justify-center min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="bg-white h-screen w-screen text-left align-middle relative overflow-y-scroll">
              <button ref={ref} className="opacity-0 absolute bottom-0"></button>
              <Close
                onClick={onClose}
                className="absolute top-6 right-6 cursor-pointer text-gray-500 hover:text-black w-5 h-5"
              />
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
