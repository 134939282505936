import React from 'react';
import { FullscreenModal } from '../FullscreenModal';
import { Pronostix } from '../../assets/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../Button';

interface RulesModalProps {
  isOpen: boolean;
  setClose: () => void;
}

interface pointsCardsItem {
  [key: string]: { points: number; color: string; subtitle: string; description: string };
}

export const RulesModal = ({ isOpen, setClose }: RulesModalProps) => {
  const { t } = useTranslation(['rules', 'forecastPanel']);
  const pointsCards: pointsCardsItem = {
    perfect: {
      points: 3,
      color: 'text-green-500',
      subtitle: t('rules:inThebullSeye'),
      description: t('rules:perfectDescription'),
    },
    partial: {
      points: 1,
      color: 'text-orange-500',
      subtitle: t('rules:close'),
      description: t('rules:partialDescription'),
    },
    miss: {
      points: 0,
      color: 'text-red-500',
      subtitle: t('rules:missed'),
      description: t('rules:missedDescription'),
    },
  };
  return (
    <FullscreenModal isOpen={isOpen} onClose={setClose}>
      <div className="p-6 flex flex-col">
        <div className="flex flex-col items-center">
          <div className="text-blue-500 flex justify-center items-center text-sm font-bold text-xl uppercase bg-blue-100 px-3 py-2 rounded-full max-w-56">
            {t('rules:gameRules')}
          </div>
        </div>
        <div className="overflow-y-scroll flex-1">
          <div className="flex justify-center">
            <div className="text-3xl font-bold mt-4 text-center">{t('rules:pointsRulesTitle')}</div>
          </div>
          <div className="flex flex-col md:flex-row mt-12 space-y-4 md:space-y-0 space-x-0 md:space-x-10 ">
            {Object.entries(pointsCards).map(([key, { points, description, color, subtitle }]) => (
              <div key={key} className="rounded-lg px-10 pb-8 pt-2 flex flex-1 flex-col">
                <div className="flex ">
                  <Pronostix className={`${color} w-20 h-20`} />
                  <div className="ml-4">
                    <div className="text-2xl font-bold mt-4">
                      {points > 0 && '+'} {t('forecastPanel:point', { count: points })}
                    </div>
                    <div className="text-lg text-gray-500">{subtitle}</div>
                  </div>
                </div>
                <div className="text-lg text-center mt-4">{description}</div>
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <div className="text-3xl font-bold mt-4 text-center">{t('rules:finalRulesTitle')}</div>
          </div>
          <div className="mt-12 space-y-2">
            <div>
              <p>{t('rules:finalRulesFirstLine')}</p>
              <p>
                <Trans i18nKey="rules:finalRulesSecondLine">
                  Basically, make your prediction on the score of the match <strong>excluding penalties</strong>.
                </Trans>
              </p>
            </div>

            <p>{t('rules:finalRulesThirdLine')}</p>

            <p>{t('rules:finalRulesFourthLine')}</p>
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <Button onClick={setClose}>{t('rules:understood')}</Button>
        </div>
      </div>
    </FullscreenModal>
  );
};
