import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';

interface LinkProps {
  variant: 'blue' | 'violet';
  disabled?: boolean;
  to?: string;
  href?: string;
  onClick?: any;
  target?: string;
  rel?: string;
}

export const LinkButton: React.FC<LinkProps> = ({ children, variant = 'blue', disabled, ...rest }) => {
  const variants = {
    blue: ['text-blue-500'],
    violet: ['text-purple-500'],
  };
  const defaultStyle = [
    'cursor-pointer',
    'leading-normal',
    'font-semibold',
    'hover:underline',
    'focus:underline',
    'focus:outline-none',
    'select-none',
  ];

  let className = clsx(
    defaultStyle,
    variants[variant],
    {
      'opacity-50 pointer-events-none': disabled,
    },
    disabled && 'pointer-events-none',
  );

  if (rest.to) {
    return (
      <Link href={rest.to} {...rest}>
        <a className={className} aria-disabled={disabled}>
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button className={className} disabled={disabled} {...rest}>
      {children}
    </button>
  );
};
